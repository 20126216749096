import styled from 'styled-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { th } from '@smooth-ui/core-sc'

export const StyledA = styled.a`
display: block;

> h3 {
  line-height: 28px;
  padding-top: 10px;
}

&:hover {

  > h4 {
    color: ${th('primary')};
  }

  > div {
    position: relative;

    &:before {
      content: ' ';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: rgba(240,100,73,0.8);
      z-index: 2;
    }

    &:after {
      content: 'Read more';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Oswald', sans-serif;
      font-size: 32px;
      color: rgba(255,255,255,0.6);
      z-index: 3;
    }
  }

}
`


export const StyledLink = styled(Link)`
  display: block;

  > h3 {
    line-height: 28px;
    padding-top: 10px;
  }

  &:hover {

    > h4 {
      color: ${th('primary')};
    }

    > div {
      position: relative;

      &:before {
        content: ' ';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: rgba(240,100,73,0.8);
        z-index: 2;
      }

      &:after {
        content: 'Read more';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Oswald', sans-serif;
        font-size: 32px;
        color: rgba(255,255,255,0.6);
        z-index: 3;
      }
    }

  }
`