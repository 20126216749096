import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import Plx from 'react-plx'
import Img from 'gatsby-image'
import { up, css, Col } from '@smooth-ui/core-sc'
import { AbsoluteContainer } from '../../style/globalStyleComponents'

export const TopImageBanner = page => {
    const { image, screenshot1, screenshot2 } = useStaticQuery(graphql`
        {
            image: file(name: { eq: "map-hero-image" }) {
            childImageSharp {
                fluid(maxWidth: 740, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
            screenshot1: file(name: { eq: "app-screenshot-1" }) {
            childImageSharp {
                fluid(maxWidth: 230, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
            screenshot2: file(name: { eq: "app-screenshot-2" }) {
            childImageSharp {
                fluid(maxWidth: 230, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        }
    `)

  return (
    <TopImageBannerContainer>
      <AbsoluteContainer>
        <FullHeightImg fluid={image.childImageSharp.fluid} />
      </AbsoluteContainer>
      <GreyBackground/>
      <LeftSide>
        <ImagesContainer>
          <Plx parallaxData={parallax2}>
            <ImgContainer>
              <Img fluid={screenshot2.childImageSharp.fluid} />
            </ImgContainer>
          </Plx>
          <DarkParallax page={page ? 'true' : 'false'}>
            <ImgContainer>
              <Img fluid={screenshot1.childImageSharp.fluid} />
            </ImgContainer>
          </DarkParallax>
          </ImagesContainer>
      </LeftSide>
      <RightSide>
        <BannerTitle page={page ? 'true' : 'false'}>IT'S ALWAYS WISER TO TRAIN WITH A PLAN</BannerTitle>
        <ButtonContainer>
          <a href='https://apps.apple.com/us/app/sportme-marathon-run-trainer/id588594735'
             target='_blank' rel='noreferrer'
             aria-label='Get SportMe in App store'
             onClick={e => trackClickAnalytics(e)}>
                  Get SportMe
          </a>
        </ButtonContainer>
      </RightSide>
    </TopImageBannerContainer>
  )
}

const TopImageBannerContainer = styled.div`
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 130px;
  margin: 115px auto 0;
  position: relative;
  
  ${up('md', css`
    height: 200px;
  `)}
`

const GreyBackground = styled.div`
  background:rgba(1,1,1,0.4);
  bottom: 10px;
  left: 0;
  position: absolute;
  right: 0;
  overflow: hidden;
  top: 10px;  

  ${up('md', css`
    bottom: 20px;
    top: 20px;  
  `)}
`

const LeftSide = styled.div`
  margin-bottom: 10px;
  overflow: hidden;
  width: 48%;
  
  ${up('md', css`
    margin-bottom: 20px;
    width: 40%;
  `)}
`

export const ImagesContainer = styled(Col)`
  transform: translateX(22%);
   ${up('sm', css`
    transform: translateX(40%);
  `)}
`

export const FullHeightImg = styled(Img)`
  max-height: 200px;
`

export const DarkParallax = styled(Plx)`
  left: -14%;
  position: absolute;
  top: 20px;
  
  ${up('sm', css`
    left: -10%;
  `)}

  ${up('md', css`
    top: 25px;
    left: -28%;
  `)}
  
  ${up('lg', css`
    left: ${props => props.page === 'true' ? '-20%': '-30%'};
    top: 20px;
  `)}

  ${up('xl', css`
    left: ${props => props.page === 'true' ? '-16%': '-25%'};
  `)}
`

export const ImgContainer = styled.div`
  max-width: 60px;
  width: 50vw;
  
  ${up('md', css`
     max-width: 120px;
  `)}
`
const parallax2 = [{
  start: 0,
  end: 400,
  properties: [{
    startValue: 55,
    endValue: -100,
    property: 'translateY',
  },],
}]

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: 10px;
  width: 50%;
  z-index: 1;
  
  ${up('md', css`
    width: 64%;
  `)}  
`

const BannerTitle = styled.span`
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 10px 0;
  text-align: center;  
  
  ${up('sm', css`
    font-size: 18px;
    margin: 0 15px 5px 0;
  `)}  

  ${up('md', css` 
    font-size: 26px;
    margin: 0 15px 5px 0;  
  `)}  
  
  ${up('xl', css`
    font-size: ${props => props.page === 'true' ? '36px' : '24px' };
  `)}
`

const ButtonContainer = styled.div`
  max-width: 240px;
  margin: 0 auto;
  width: 100%;
  
  a {
    align-items: center;
    border-radius: 30px;
    background-color: #F06449;
    color: #fff;
    display: flex;
    font-size: 14px;
    font-weight: 700;
    justify-content: center;
    height: 40px;
    margin: 0 auto;
    text-align: center;
    width: 130px;
  
  ${up('md', css`
    font-size: 24px;
    line-height: 60px;
    height: 60px;
    width: 240px;

    &:hover {
      background-color: #F04F30;
    }
  `)}  
  }
`

const trackClickAnalytics = e => {
  trackCustomEvent({
    category: 'button',
    action: 'Click',
    label: 'TopCTADownload'
  })
}
