import React from 'react'
import { Helmet } from 'react-helmet'
import moment from 'moment';

export const StructuredData = (name, author, datePublished, url, image, publisherName,
  structuredDataHeadline, structuredDataDesc, structuredData) => {

  let scriptData = {
    "@context": "http://schema.org",
    "@type": "Article",
    "name": `${name}`,
    "url": `${url}`,
    ...structuredDataHeadline && {"headline": `${structuredDataHeadline}`},
    ...author && {"author": {
      "@type": "Person",
      "name": `${author}`
    }},
    "datePublished" : `${moment(datePublished).format('YYYY-MM-DD')}`,
    "mainEntityOfPage" : "WebPage",
    "image" : `${image}`,
    ...publisherName && {"publisher" : {
      "@type" : "Organization",
      "name" : `${publisherName}`
    }},
    "logo" : {
      "@type" : "ImageObject",
      "url" : "https://www.sportme.com/static/logo-aab1cafd4ffa03199bdbcc9da9fdb542.svg"
    },
    "description" : `${structuredDataDesc}`
  }

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(scriptData)}</script>
        { structuredData && <script type="application/ld+json">{structuredData.trim()}</script> }
    </Helmet>
  );
};
