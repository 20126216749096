import React, { useLayoutEffect } from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { up, css } from '@smooth-ui/core-sc'

import facebookSvg from '../../images/Facebook.svg';
import twitterSvg from '../../images/Twitter.svg';
import pinterestSvg from '../../images/pinterest.svg';

const SocialShareContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 22px;
  width: 100%;

  ${up('lg', css`
    margin-top: 50px;
  `)}
`

const ShareTitle = styled.span`
  color: #1A1A1A;	
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;

  ${up('lg', css`
    font-size: 24px;
    line-height: 28px;
  `)}
`

const ShareElements = styled.div`
  border-bottom: 1px solid #bababa;
  border-top: 1px solid #bababa;
  display: flex;
  flex-direction: row;
  padding: 10px 0 12px 0;

  ${up('lg', css`
    padding: 10px 0 9.5px 0;
    margin-bottom: 79.5px;
  `)}  
`

const ElementContainer = styled.div`
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: flex;
  height: 32px;
  margin-right: 10px;
  width: 32px;
  img {
    height: 100%;
    width: 100%;
  }

  ${up('lg', css`
    height: 60px;
    margin-right: 30px;
    width: 60px; 
    
    img {
      opacity: 0.6;
      &:hover {
        opacity: 1;
      }
    }
  `)} 
`

export const SocialShare = (shareUrl, shareDescription, title, image) => {
  useLayoutEffect(() => {
    loadHeaderMetasAndScripts(shareUrl, shareDescription);
  }, []);

  return (
    <SocialShareContainer>
        <ShareTitle>Share</ShareTitle>
        <ShareElements>
          <ElementContainer onClick={() => shareOnTwitter(shareUrl)} aria-label='share to twitter'>
            <img src={twitterSvg} />
          </ElementContainer>
          <ElementContainer onClick={() => shareOnFb(shareUrl)} aria-label='share to facebook'>
            <img src={facebookSvg} />
          </ElementContainer>
          <ElementContainer p onClick={() => shareOnPinterest(shareUrl, image, shareDescription)} aria-label='share to pinterest'>
            <img src={pinterestSvg} />
          </ElementContainer>
        </ShareElements>
    </SocialShareContainer>
  )
}

const loadHeaderMetasAndScripts = (shareUrl, shareDescription) => (
    <Helmet>
        <div id='fb-root'/>
        <script async defer src='https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v5.0'/>
    </Helmet>
);

const shareOnFb = shareUrl => {
    window.open('http://facebook.com/sharer/sharer.php?u='+encodeURIComponent(shareUrl), '', 'left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0');

};

const shareOnTwitter = shareUrl => {
    window.open('https://twitter.com/share?url=' + shareUrl, 'twitter-popup', 'height=350,width=600');
}

const shareOnPinterest = (url, image, desc) => {
    window.open(`http://pinterest.com/pin/create/button/?url=${encodeURIComponent(url)}&media=${encodeURIComponent(image)}&description=${desc}`, 'pinterestwindow','left=20,top=20,width=500,height=500,toolbar=0,resizable=1');
}
