import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Row, Col, Box, theme } from '@smooth-ui/core-sc'

import { StyledA } from './styles'
import { renderDate } from '../../utils/utils'
import { VerticalSpacer, RoundedCornerImg, MainSection, SectionScrollAnchor,
    RelatedDateAndAuthor, RelatedDate, RelatedAuthor } from '../../style/globalStyleComponents'
import { Button } from '../Button/Button'

export const BlogPreview = (props) => {
  const { blogposts, innerRef } = props
  return (
    <MainSection>
      <SectionScrollAnchor ref={innerRef} />
      <Grid>
        <Row>
          <Col xs={12}>
            <VerticalSpacer>
              <h1>{props.title1}</h1>
              <h3>{props.subtitle1}</h3>
            </VerticalSpacer>
          </Col>
        </Row>
        <ArticleList articles={blogposts} perRow={3} />
        <Row>
          <Col>
            <VerticalSpacer>
              <h1>{props.title2}</h1>
              <h3>{props.subtitle2}</h3>
              <Box mt="40px">
                <Button link="/blog">Go to Blog</Button>
              </Box>
            </VerticalSpacer>
          </Col>
        </Row>
      </Grid>
    </MainSection>
  )
}

export const ArticleList = ({ articles, perRow = 1 }) => {
  const colSize = 12 / perRow
  return (
    <Row>
      {articles.map(({ node }, idx) => (
        <Col xs={12} md={colSize} mb={{ xs: '40px', md: '20px' }} key={idx}>
          <StyledA href={`/blog/${node.uid}`} target='_blank' aria-label={`link to ${node.data.title.text}`}>
            <RoundedCornerImg fluid={node.data.thumbnail.localFile.childImageSharp.fluid} />
            <h3>{node.data.title.text}</h3>
          </StyledA>
          <RelatedDateAndAuthor>
            <RelatedDate>{renderDate(node.last_publication_date)}</RelatedDate>
            { node.data.author && node.data.author.text && <RelatedAuthor>by {node.data.author.text}</RelatedAuthor> }
          </RelatedDateAndAuthor>
        </Col>
      ))}
    </Row>
  )
}

BlogPreview.propTypes = {
  blogposts: PropTypes.array.isRequired,
  innerRef: PropTypes.any,
  title1: PropTypes.string.isRequired,
  title2: PropTypes.string.isRequired,
  subtitle1: PropTypes.string.isRequired,

}