import React, { useLayoutEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Grid, Row, Col } from '@smooth-ui/core-sc'

import { MainLayout } from '../layouts/MainLayout'
import { Header } from '../components/Header/Header'
import { ArticleList } from '../components/BlogPreview/BlogPreview'
import { RoundedCornerImg, VerticalSpacer, StyledLink, Author, DateAndAuthor, PublishedBy,
    ImageCaption, SpacerDesktop } from '../style/globalStyleComponents'
import { renderDate } from '../utils/utils'
import { theme  } from '../style/theme'
import { StructuredData } from '../scripts';
import { ImageBanner } from '../components/ImageBanner'
import { TopImageBanner } from '../components/TopImageBanner'
import { SocialShare } from '../components/SocialShare'
import { Search } from '../components/Search'

export const query = graphql`
query ($id: String, $tags: [String!]!) {

  site {
    siteMetadata {
      siteUrl
    }
  }

  prismicBlogpost(id: {eq: $id}) {
    uid
    last_publication_date
    data {
      title {
        text
      }
      meta_description
      published_by {
        text
      }
      author {
        text
      }
      content {
        html
      }
      structured_data {
        structured_data_description
        structured_data_headline
        structured_data_content 
      }
      image_caption {
        text
        html
      }
      thumbnail {
        alt  
        localFile {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }

  related: allPrismicBlogpost(filter: {tags:{in : $tags}, id: { ne: $id }}, limit: 3) {
	edges {
      node {
        uid
        last_publication_date
        data {
          content {
            text
          }
          title {
            text
          }
          author {
            text
          }
          thumbnail {
            alt
            localFile {
              childImageSharp {
                fluid(maxWidth: 600, maxHeight: 420) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }

  urlsAndTags: allSitePage(filter: { path: { ne: "/404/" }, id: { ne: $id } }) {
    nodes {
      path
      context {
        tags
      }
    }
  }  
}
`

const _renderFaqStructuredData = (post, last_publication_date, location, imageSrc) => {
  const { title, author, published_by, structured_data } = post
  const { structured_data_headline , structured_data_description ,
    structured_data_content } = structured_data && structured_data[0]

  return (
    StructuredData(title.text, author.text, last_publication_date, location.href, imageSrc,
      published_by.text, structured_data_headline, structured_data_description,
      structured_data_content)
  )
};

export default ({ data, location }) => {
  const { prismicBlogpost, related, site } = data
  const { last_publication_date } = prismicBlogpost
  const post = prismicBlogpost.data
  const { title, structured_data, author, published_by, meta_description, thumbnail, image_caption } = post
  const hasStructuredData = structured_data && structured_data.length > 0
  const imageSrc = `${location.origin}${thumbnail.localFile.childImageSharp.fluid.src}`
  let faqStructuredData = null
  if (hasStructuredData) {
      faqStructuredData = _renderFaqStructuredData(post, last_publication_date, location, imageSrc);
  }
  const topImageBanner = TopImageBanner(false);
  const imageBanner = ImageBanner();
  const socialShare = SocialShare(location.href, meta_description, title.text, imageSrc);
  const [showSearch, setShowSearch] = useState(false)
  const search = Search()

  useLayoutEffect(() => setShowSearch(true), []);

  return (
    <MainLayout pathname={location.pathname}>
      <Helmet
        title={title.text}
        meta={[{
          property: 'og:title',
          content: title.text,
        }, {
          property: 'og:image',
          content: `${site.siteMetadata.siteUrl}${post.thumbnail.localFile.childImageSharp.fluid.src}`,
        }, {
          property: 'og:url',
          content: `${location.href}`,
        }, {
            property: 'og:description',
            content: `${meta_description}`,
        }, {
            property: 'og:type',
            content: 'article',
        },{
            property: 'og:image:alt',
            content: `${thumbnail.alt}`,
        },

        ]} >
      </Helmet>
      { faqStructuredData }
      <Header pathname={location.pathname} />
      <Grid>
        <Row>
          <Col xs={12} lg={9} xl={7.8} gutter={{ xs: theme.gridGutter, lg: 6 }}>
              { topImageBanner }
            <VerticalSpacer small left>
              <h1>{post.title.text}</h1>
              <DateAndAuthor>
                <h3 style={{ color: 'rgba(26,26,26,0.3)' }}>{renderDate(prismicBlogpost.last_publication_date)}</h3>
                { author && author.text && <Author>by {author.text}</Author> }
              </DateAndAuthor>
            </VerticalSpacer>
            <RoundedCornerImg fluid={post.thumbnail.localFile.childImageSharp.fluid} />
            {image_caption && <ImageCaption dangerouslySetInnerHTML={{ __html: image_caption.html }}/> }
            <StyledLink dangerouslySetInnerHTML={{ __html: post.content.html }} />
            { published_by && published_by.text &&
              <PublishedBy >published by: <span>{published_by.text}</span></PublishedBy> }
            { imageBanner }
            { socialShare }
          </Col>

          <SpacerDesktop>
            <Col xl={0.4} />
          </SpacerDesktop>

          <Col xs={12} lg={3} xl={3.726} pt={{ xs: '50px', md: '108px' }}>
            { showSearch && search }
            <h2>Related articles</h2>
            <ArticleList articles={related.edges} />
          </Col>
        </Row>
      </Grid>
    </MainLayout>
  )
}

