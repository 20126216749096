import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { OutboundLink, trackCustomEvent } from 'gatsby-plugin-google-analytics'

export const ImageBanner = () => {
  const { banner } = useStaticQuery(graphql`{
    banner: file(name: { eq: "sport_me_banner" }, ext: { eq: ".jpg" }) {
        publicURL
      }
    }
 `)
  return (
    <OutboundLink href='https://apps.apple.com/us/app/sportme-marathon-run-trainer/id588594735'
                  target='_blank' rel='noreferrer'
                  aria-label='Get SportMe in App store'
                  onClick={e => trackClickAnalytics(e)}>
      <p>
        <img src={banner && banner.publicURL} alt='SportMe Marathon Trainer banner' copyright='true'/>
      </p>
    </OutboundLink>
  )
}

const trackClickAnalytics = e => {
  trackCustomEvent({
    category: 'button',
    action: 'Click',
    label: 'BottomCTADownload'
  })
}